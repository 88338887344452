// src/components/Search.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { NavLink } from 'react-router-dom';
import { FaStar, FaSearch, FaTimes, FaThumbsUp, FaPlay } from 'react-icons/fa'; // Import the search, star, and clear icons
import '../styles/Search.css';

const genresList = ['Rock', 'Pop', 'Hip-Hop', 'Electronic', 'Jazz', 'Classical', 'Country', 'Blues'];
const trackTypes = ['vocals', 'beat', 'both'];

const Search = ({ handleTrackClick }) => {
  const [trackQuery, setTrackQuery] = useState(''); 
  const [selectedGenres, setSelectedGenres] = useState([]); 
  const [trackType, setTrackType] = useState('');
  const [results, setResults] = useState([]); 
  const [currentUserId, setCurrentUserId] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setCurrentUserId(user.uid);
    }
  }, [auth]);

  const fetchSearchResults = async () => {
    if (trackQuery.trim() === '' && selectedGenres.length === 0 && trackType === '') {
      setResults([]); // Clear results if all search criteria are empty
      return;
    }

    try {
      const response = await axios.get('https://sync-api-troz.onrender.com/api/search', {
        params: {
          trackName: trackQuery, 
          genres: selectedGenres,
          trackType: trackType,
        },
      });
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(fetchSearchResults, 500); // Delay for 500ms to avoid excessive API calls

    return () => clearTimeout(delayDebounceFn); // Clear the timeout if the user keeps typing
  }, [trackQuery, selectedGenres, trackType]);

  const clearSearch = () => {
    setTrackQuery('');
    setResults([]);
  };

  const handleGenreClick = (genre) => {
    if (!selectedGenres.includes(genre)) {
      setSelectedGenres([...selectedGenres, genre]);
    } else {
      setSelectedGenres(selectedGenres.filter((g) => g !== genre));
    }
    fetchSearchResults(); // Trigger the search immediately after updating genres
  };

  const handleStarTrack = async (trackId, trackUserId) => {
    if (trackUserId === currentUserId) {
      alert("You can't star your own track.");
      return;
    }

    try {
      await axios.post('https://sync-api-troz.onrender.com/api/uploads/star', {
        trackId,
        userId: currentUserId,
      });

      alert("Track starred successfully!");
      setTrackQuery(''); // Clear the search input after starring
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === 'You have already starred a track from this artist'
      ) {
        alert('You already starred a track from this user.');
      } else {
        console.error('Error starring track:', error);
        alert('An error occurred while starring the track.');
      }
    }
  };

  const handleLikeTrack = async (trackId, trackUserId) => {
    if (trackUserId === currentUserId) {
      alert("You can't like your own track.");
      return;
    }
  
    try {
      await axios.post('https://sync-api-troz.onrender.com/api/uploads/like', {
        trackId,
        firebaseUid: currentUserId,
      });
  
      alert("Track liked successfully!");
      fetchSearchResults(); // Refresh the search results to reflect the updated like count
    } catch (error) {
      console.error('Error liking track:', error);
      alert('An error occurred while liking the track.');
    }
  };

  return (
    <div className="search-container">
      <h2>Search Music</h2>

      {/* Sticky search input container */}
      <div className="sticky-search-bar">
        <div className="search-input-container">
          <FaSearch className="search-icon" />
          <input
            id="track-search"
            type="text"
            value={trackQuery}
            onChange={(e) => setTrackQuery(e.target.value)}
            placeholder="Search by track name"
            className="search-input"
          />
          {trackQuery && (
            <FaTimes className="clear-icon" onClick={clearSearch} />
          )}
        </div>
      </div>

      {/* Genre and track type filters */}
      <div className="form-group">
        <select
          value={trackType}
          onChange={(e) => setTrackType(e.target.value)}
          className="search-input"
        >
          <option value="">All Track Types</option>
          {trackTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <div className="genres-list">
          {genresList.map((genre) => (
            <button
              key={genre}
              className={`genre-button ${selectedGenres.includes(genre) ? 'selected' : ''}`}
              onClick={() => handleGenreClick(genre)}
            >
              {genre}
            </button>
          ))}
        </div>
      </div>

      {/* Search results */}
      <div className="search-results discography-card-container">
        {results.length > 0 ? (
          results.map((upload) => (
            <div key={upload._id} className="upload-item" onClick={() => handleTrackClick(upload)}>
              <div className='upload-item-container'>
                {upload.coverPhoto && (
                  <img src={upload.coverPhoto} alt="cover-photo" />
                )}

                <div className='upload-details'>
                  <h3>{upload.title}</h3>
                  <p>Track Type: <span style={{ color: '#ff5349' }}>{upload.trackType}</span></p>
                  <p className="discography-genre" style={{ color: '#ff5349' }}>{upload.genre}</p>
                  <p>By: {upload.artistName}</p>
                  <div className="star-section">
                    <FaPlay className='play-icon-small'/>
                    <span className="play-count">{upload.plays}</span>
                    <FaStar
                      className="star-icon"
                      onClick={() => handleStarTrack(upload._id, upload.user)}
                      style={{ color: upload.user === currentUserId ? '#ccc' : '#fff' }}
                    />
                    <span className="star-count">{upload.stars}</span>

                    {/* Like Section */}
                    <FaThumbsUp
                      className="like-icon"
                      onClick={() => handleLikeTrack(upload._id, upload.user)}
                      style={{ color: upload.likedBy && upload.likedBy.includes(currentUserId) ? '#ff5349' : '#fff' }}
                    />
                    <span className="like-count">{upload.likes}</span>
                  </div>

                </div>
              </div>
            </div>
          ))
        ) : (
          <p className='no-results'>No results found. Upload a track yourself!</p>
        )}
      </div>
    </div>
  );
};

export default Search;
