import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { NavLink } from 'react-router-dom';
import '../styles/Home.css';
import { FaThumbsUp, FaPlay } from 'react-icons/fa'; // Import the heart and play icons

const Home = ({ handleTrackClick }) => {
  const [uploads, setUploads] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentUser = getAuth().currentUser;

  useEffect(() => {
    const fetchMatchedUploads = async () => {
      try {
        if (currentUser) {
          const token = await currentUser.getIdToken(); // Fetch the Firebase Auth token
          const response = await axios.get('https://sync-api-troz.onrender.com/api/uploads/matched-uploads', {
            params: { firebaseUid: currentUser.uid },
            headers: {
              Authorization: `Bearer ${token}` // Pass the token in the Authorization header
            }
          });
          setUploads(response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching matched uploads:', error);
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchMatchedUploads();
    }
  }, [currentUser]);

  const handleLikeTrack = async (trackId, trackUserId) => {
    if (trackUserId === currentUser.uid) {
      alert("You can't like your own track.");
      return;
    }
  
    try {
      const token = await currentUser.getIdToken(); // Fetch the Firebase Auth token
      await axios.post('https://sync-api-troz.onrender.com/api/uploads/like', {
        trackId,
        firebaseUid: currentUser.uid,
      }, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
  
      // Update the uploads state to reflect the new like count
      setUploads((prevUploads) =>
        prevUploads.map((upload) =>
          upload._id === trackId
            ? { ...upload, likes: (upload.likes || 0) + 1, likedBy: [...(upload.likedBy || []), currentUser.uid] }
            : upload
        )
      );
  
      alert('Track liked successfully!');
    } catch (error) {
      console.error('Error liking track:', error);
      alert('An error occurred while liking the track.');
    }
  };
  

  if (loading) return <div>Loading...</div>;

  if (!uploads.length) return (
    <div className='empty-home-message'>
      <h2 className='home-page-header' style={{fontStyle: 'normal'}}>Collaborators' Uploads</h2><br />
      No matched artists' uploads available. Go to the search tab and send out stars! 
      <br /> 
      <NavLink className='empty-home-go-to-search-btn' to='/search'>
        Search
      </NavLink>
    </div>
  );

  return (
    <div className="home-container">
      <h2 className='home-page-header'>Collaborators' Uploads</h2>
      <div className="music-feed">
        {uploads.map(upload => (
          <div key={upload._id} className="upload-item" onClick={() => handleTrackClick(upload)}>
            <div className='upload-item-container'>
              <img src={upload.coverPhoto} alt={upload.title} className="upload-cover" />
              <div className="upload-details">
                <h3>{upload.title}</h3>
                <p className='home-artist-upload'>{upload.user.artistName}</p>
                <p className='home-genre-upload'>{upload.genre}</p>

                {/* Display likes and plays */}
                <div className="likes-plays-section">
                  
                  <span className="plays-count">
                    <FaPlay className="play-icon" style={{marginRight: '5px'}} /> {upload.plays || 0}
                  </span>
                  <span
                    className={`likes-count ${upload.likedBy && upload.likedBy.includes(currentUser.uid) ? 'liked' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the track click event
                      handleLikeTrack(upload._id, upload.user._id);
                    }}
                  >
                    <FaThumbsUp className="like-icon" style={{marginRight: '5px'}} /> {upload.likes || 0}
                  </span>
                </div>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
